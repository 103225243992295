.terms-and-conditions {
  h3 {
    text-transform: uppercase;
  }
  &.page header .header-block {
    padding: 150px 0 60px;
  }
  .container {
    padding: 100px 0;
  }
}
