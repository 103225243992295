.home-page {
  header {
    background-image: url("../../assets/images/home/slider-after.svg");
    background-size: cover;
    background-position: bottom;
    padding-bottom: 250px;
    position: relative;
    padding-top: 100px;
    .slider-content {
      padding: 40px 0;
      @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
      }

      span {
        display: block;
        font-size: 24px;
        color: #ffffff;
        margin-bottom: 20px;
        &.heading {
          font-size: 35px;
          margin-bottom: 60px;
          font-weight: 900;
          line-height: 2;
        }
        &.sub-heading {
          font-weight: 900;
        }
      }
      .feature-list {
        font-size: 24px;
        color: #ffffff;
        position: relative;
        height: 250px;
        overflow: hidden;
        -webkit-mask-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 0)
        );
        -webkit-mask-size: 100%;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: bottom;
        & > div {
          width: 100%;
          position: absolute;
          top: 0;
          animation: 30s roll-out linear infinite;
        }
      }
      .cta {
        background-color: #f9a215;
        color: #ffffff;
        padding: 10px 20px;
        outline: none;
        border: none;
        font-size: 16px;
        border-radius: 5px;
        box-shadow: 5px 5px 5px #00000016;
        margin-top: 40px;
      }
      .slider-left {
        flex: 1;

        .coming-soon {
          margin-top: 90px;
          margin-bottom: 90px;
          font-weight: 600;
        }
      }
      .slider-right {
        flex: 1;
        position: relative;
        img {
          padding: 50px;
          max-width: 100%;
        }
        .animated-icon {
          &:nth-child(1) {
            animation-duration: 17s;
            top: 4%;
            right: 9%;
          }
          &:nth-child(2) {
            animation-duration: 13s;
            bottom: 18%;
            right: 22%;
          }
          &:nth-child(3) {
            animation-duration: 11s;
            top: 38%;
            left: 13%;
          }
          &:nth-child(4) {
            animation-duration: 9s;
            bottom: 18%;
            left: 7%;
          }
          &:nth-child(5) {
            animation-duration: 15s;
            top: 40%;
            right: 31%;
            font-size: 12px;
            line-height: 11px;
          }
        }
      }
    }
  }
  section {
    padding: 35px 0;
  }
  .how-we-can-help-section {
    padding: 50px 0;
    position: relative;
    span {
      display: block;
      text-align: center;
      &.heading {
        font-size: 28px;
        margin: 10px 0 20px;
      }
      &.sub-heading {
        font-size: 22px;
        color: #f99d1c;
        margin-bottom: 35px;
      }
    }
    .background-list {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      & > div {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-size: contain;
        background-position: right;
        background-repeat: no-repeat;
        animation: fade-in-fade-out 8s linear infinite alternate;
        &:nth-child(1) {
          background-image: url("../../assets/images/home/howWeCanHelp/network-graphic-bg.jpg");
          animation: none;
        }
        &:nth-child(2) {
          background-image: url("../../assets/images/home/howWeCanHelp/network-graphic-bg-1.png");
          animation-duration: 17s;
        }
        &:nth-child(3) {
          background-image: url("../../assets/images/home/howWeCanHelp/network-graphic-bg-2.png");
          animation-duration: 13s;
        }
        &:nth-child(4) {
          background-image: url("../../assets/images/home/howWeCanHelp/network-graphic-bg-3.png");
          animation-duration: 11s;
        }
        &:nth-child(5) {
          background-image: url("../../assets/images/home/howWeCanHelp/network-graphic-bg-4.png");
        }
        &:nth-child(6) {
          background-image: url("../../assets/images/home/howWeCanHelp/network-graphic-bg-5.png");
          animation-duration: 15s;
        }
      }
    }
    .services-list {
      @media screen and (min-width: 768px) {
        display: flex;
      }
      margin: 0 -10px;
      &-item {
        flex: 1;
        background-color: #38c6f4;
        margin: 0 10px;
        border-radius: 10px;
        color: #ffffff;
        line-height: 1.5;
        padding: 160px 30px 30px;
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 20px;
        img {
          height: 100px;
          position: absolute;
          top: 20px;
          right: 0px;
          opacity: 0.1;
        }
        &-heading {
          font-size: 24px;
          margin: 0 0 20px;
        }
        .button-container {
          text-align: center;
          a {
            color: #fff;
            border: 1px solid #fff;
            display: inline-block;
            padding: 5px 30px;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .why-utogi-section {
    @media screen and (min-width: 768px) {
      display: flex;
    }
    background-size: auto;
    padding: 50px 0;
    &-intro {
      flex: 0 0 40%;
      padding-right: 50px;
      span {
        font-size: 28px;
      }
      p {
        color: #808080;
        line-height: 2;
      }
      a {
        display: inline-block;
        background-color: #f9a215;
        padding: 15px 30px;
        color: #ffffff;
        font-weight: 500;
        border-radius: 5px;
        box-shadow: 0 7px 8px #00000010;
        margin-bottom: 25px;
      }
    }
  }
  .all-products-section {
    @media screen and (min-width: 768px) {
      display: flex;
    }
    align-items: center;
    & > div {
      flex: 1;
    }
    img {
      width: 500px;
      max-width: 100%;
    }
    p {
      line-height: 2;
      &.heading {
        line-height: 1.3;
        font-size: 22px;
        color: #0099ff;
      }
      margin-bottom: 30px;
    }
    .our-products {
      background-color: #f9a215;
      color: #ffffff;
      box-shadow: 0 0 5px #00000016;
      padding: 10px 20px;
      font-size: 12px;
      border-radius: 3px;
    }
  }
  @media screen and (max-width: 520px) {
    .animated-icon {
      display: none;
    }
  }
  .utogi-feature-list-item .heading img {
    margin-right: 10px;
    max-width: 40px;
  }
  footer {
    ul {
      list-style: none;
      margin: 0;
      padding: 10px 0;
      display: flex;
      justify-content: center;
      li {
        padding: 0 10px;
        a {
          font-size: 14px;
          color: #7e7e7e;
          font-weight: 600;
        }
        &:not(:last-child){
          border-right: 1px solid #e7e7e7
        }
      }
    }
  }
}

.get-started-block {
  @media screen and (min-width: 768px) {
    display: flex;
  }
  img {
    max-width: 100px;
    margin-top: -100px;
    margin-left: -20px;
  }
  &-container {
    margin: 0 10px 15px;
    background: #ffffff;
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    &-inner {
      padding: 20px;
      span {
        color: #0099ff;
        font-size: 24px;
      }
      p {
        color: #999999;
        line-height: 1.8;
        font-size: 17px;
      }
    }
  }
}
.get-started-section {
  background-image: url("../../assets/images/home/get-started-bg.svg");
  padding-bottom: 150px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  .featured {
    padding: 50px;
    background: #fff;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 0 66px #00000028;
    span {
      font-size: 34px;
    }
    a {
      background-color: #0099ff;
      padding: 15px 50px;
      border-radius: 5px;
      display: inline-block;
      color: #ffffff;
      box-shadow: 0 7px 8px #00000010;
    }
    p {
      color: #808080;
      line-height: 1.8;
      margin: 30px 0;
    }
  }
  .animated-icon {
    &:nth-child(1) {
      top: 10%;
      right: 2%;
    }
    &:nth-child(2) {
      top: 19%;
      left: 8%;
    }
    &:nth-child(3) {
      top: 43%;
      left: 4%;
      right: auto;
    }
    &:nth-child(4) {
      top: 18%;
      right: 8%;
    }
    &:nth-child(5) {
      top: 38%;
      right: 31%;
    }
  }
}
